import React from 'react'
import styled, { keyframes} from 'styled-components'
import img1 from '../../assets/Nfts/tv1_v2.png';
import img2 from '../../assets/Nfts/tv3.png';
import img3 from '../../assets/Nfts/car1.png';
import img4 from '../../assets/Nfts/car2.png';
import img5 from '../../assets/Nfts/pool.png';
import img6 from '../../assets/Nfts/walk.png';
import img7 from '../../assets/Nfts/bbq.png';
import img8 from '../../assets/Nfts/lp.png';
import img9 from '../../assets/Nfts/stereo.png';
import { useRef } from 'react';

const Section = styled.section
`
min-height: 100vh;
width: 100vw;
background-color: ${props => props.theme.body};
color: ${props => props.theme.text};
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
position: relative;
overflow-x: hidden;
&>*:first-child{
  animation-duration: 20s;
}
&>*:last-child{
  animation-duration: 15s;
}

`

const move = keyframes `
0% {transform: translateX(100%)};
100% {transform: translateX(-100%)};
  }

` 



const Row = styled.div`
width: 100%
white-space: nowrap;
box-sizing: content-box;
margin: 2rem 0;
display: flex;

animation: ${move} 15s linear infinite ${props => props.direction} ;
@media (max-width: 48em){
  animation: ${move} 15s linear infinite ${props => props.direction} ;
  }
 
`
const ImgContainer = styled.div`

width: 100%;
margin: 0 1rem auto;
background-color: ${props => props.theme.body};
border-radius: 20px;
cursor: pointer;
justify-content: center;


img{
width: 400px;
height: auto;

}

`
const Title = styled.h1`
font-family: "Caveat Brush", cursive;
font-size: ${props => props.theme.fontxxxl};
text-transform: capitalize;
color: ${props => props.theme.text};
align-self: center;
justify-content: center;
margin: 1rem auto;
display: flex;
width: fit-content;
@media (max-width: 48em){
  font-size: ${props => props.theme.fontxxl};
}

`

const NFTItem = ({img, number=0, price=0, passRef}) => {
  let play = (e) => {
    passRef.current.style.animationPlayState = 'running';
  }
  let pause = (e) => {
    passRef.current.style.animationPlayState = 'paused';
  }

  return(
    <ImgContainer onMouseOver={e => pause(e)} onMouseOut={e => play(e)}>
    <img src={img} alt=""></img>
    </ImgContainer>
  )

}



const Showcase = () => {
  const Row1Ref = useRef(null);
  const Row2Ref = useRef(null);
  
  return (
    <Section id="collectibles">
<Title>Win Collectibles</Title>
<Row direction="none" ref={Row1Ref}>
<NFTItem img={img1} passRef = {Row1Ref}/>
<NFTItem img={img2} passRef = {Row1Ref}/>
<NFTItem img={img3} passRef = {Row1Ref}/>
<NFTItem img={img4} passRef = {Row1Ref}/>
<NFTItem img={img5} passRef = {Row1Ref}/>
</ Row>

<Row direction="reverse" ref={Row2Ref}>
<NFTItem img={img6} passRef = {Row2Ref}/>
<NFTItem img={img7} passRef = {Row2Ref}/>
<NFTItem img={img8} passRef = {Row2Ref}/>
<NFTItem img={img9} passRef = {Row2Ref}/>

</Row> 


    </Section>
  )
}

export default Showcase