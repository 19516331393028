import React, { useState } from 'react'
import styled from 'styled-components'
import Logo from "./Logo";
//import Buttonred from "./Buttonred";
//import Button from "./Button";


//import LogoText from 
const Section = styled.section`
width: 100vw;
background-color: ${props => props.theme.body};
`
const Menu = styled.ul`
display: flex;
justify-content: space-between;
align-items: center;
list-style:none;

@media (max-width: 64em)
{
  position: fixed;
  top: ${props => props.theme.navHeight};
  left: 0;
  rigth: 0;
  bottom: 0;
  width: 100vw;
  height: ${props => `calc(100vh - ${props.theme.navHeight})`};
  z-index: 50;
  Background-color: ${props => `rgba(${props.theme.textRgba}, 0.7)`};
  backdrop-filter: blur(5px);

transform: ${props => props.click ? 'translateY(0)' : 'translateY(1000%)'};
transition: all 0.3 ease;
  flex-direction: column;
  justify-content: center;

  
}



`

const MenuItem = styled.li`
margin:0 1rem;
color: ${props => props.theme.text};

cursor:pointer;
font-family: "Caveat Brush", cursive;
font-size: ${props => props.theme.fontxl};
transition: all 0.3s ease;
@media (min-width: 64em)
{
  display: flex;
}

&:hover{
  transform: scale(1.1);
  }

&::after{
  
  content: ' ';
  display: block;
  width: 0%;
  height: 2px;
  background: ${props => props.theme.text};
  transition: width 0.8s ease;
}

&:hover::after{

  width: 100%;
  
  
}
@media (max-width: 64em)
{
  margin: 1rem 0;
  
  

}



`

const NavBar = styled.nav`
display: flex;
justify-content: space-between;
align-items: center;
width: 85%;
height: ${props => props.theme.navHeight};
margin: 0 auto;
margin-top: 20px;
.mobile {
  display: none;
}

@media (max-width: 48em)
{
  .desktop{
  display: none;
  }
  .mobile {
    display: inline-block;
  }
}

`

const HamburgerMenu = styled.span`
width: 20px;
height: 2px;
background: ${props => props.theme.text};
position: absolute;
top: 2rem;
left: 50%;
transform: ${props => props.click ? 'translateX(-50%) rotate(90deg)' : 'translateX(-50%) rotate(0deg)'};
display: flex;
justify-items: center;
align-items: center;
cursor: pointer;
transition: all 0.3s ease;
display: none;

@media (max-width: 64em)
{
  display: flex;
}

&::after, &::before{
content: '';
width: 20px;
height: 2px;
background: ${props => props.theme.text};
position: absolute;
}

&::after{
  top: 0.5rem;
  }
  &::before{
    bottom: 0.5rem;
    }

`

const Navigation = () => {
  const [click, setClick] = useState(false);

  const scrollTo = (id) => {
let element = document.getElementById(id);
  
element.scrollIntoView ({
behavior: 'smooth',
block: 'start',  
inline: 'nearest'

})
setClick(!click);

}
  return (
    <Section id="navigation">
      <NavBar>
<Logo />

<HamburgerMenu click={click} onClick={() => setClick(!click)}> &nbsp; </HamburgerMenu>
<Menu click={click}>
  
  <MenuItem onClick={() => scrollTo('about')}>About</MenuItem>
  <MenuItem onClick={() => scrollTo('collectibles')}>Collectibles</MenuItem>
  <MenuItem onClick={() => scrollTo('team')}>Team</MenuItem>
  <MenuItem onClick={() => scrollTo('game')}>Game</MenuItem>
 <div class="mobile"></div>
  
  </Menu>
  <div class="desktop"><Logo />
</div>
      </NavBar>
      
      </Section>
      
  )
}

export default Navigation