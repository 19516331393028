import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import IMG1 from '../assets/logo_low.png'


const LogoText = styled.h1`
font-family: 'Akaya Telivigala' , cursive;
font-size: ${props => props.theme.fontxxxl};
color: ${props => props.theme.text};
transition: all .4s ease;
&:hover{
  transform: scale(1.1);
  }

`

const Logo = () => {
  return (
   <LogoText>
     <Link to="/">
      
      
</Link>

<img src={IMG1} width="80px" alt=""></img>
   </LogoText>
  )
}

export default Logo