import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import IMG1 from '../assets/Nfts/discord.png'


//const ImgContainer = styled.img`
//width: 200px;`

const LogoText = styled.h1`
font-family: 'Akaya Telivigala' , cursive;
font-size: ${props => props.theme.fontxxxl};
color: ${props => props.theme.text};
transition: all .4s ease;
&:hover{
  transform: scale(1.1);
  }

`

const Discord = () => {
  return (
   <LogoText>
     <Link to="/">
      
      
</Link>
<a target="_blank" href="https://discord.gg/sJnWmpMBv4" rel="noreferrer">

<img src={IMG1} width="120px" alt=""></img></a>
   </LogoText>
  )
}

export default Discord