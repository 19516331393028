import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Carousel from '../Carousel'
import Button from '../Button'
import {dark} from '../../styles/Themes';
//import {BrowserRouter as Router, Link} from 'react-router-dom';

const Section = styled.section
`
min-height: 100vh;
width: 100%;
background-color: ${props => props.theme.text};
color: #FFF;
display:flex;
justify-content: center;
align-items: center;
position: relative;

`
const Container = styled.div`
width: 75%;
min-height: 80vh;
margin: 0 auto;
background-color: ${props => props.theme.text};
display: flex;
justify-content: center;
align-items: center;
@media (max-width: 64em){
  flex-direction: column;
  width: 100%;
  }
`

const Box = styled.div`
width: 50%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media (max-width: 64em){
  width:90%;
  }

  @media (max-width: 48em){
    width:100%;
    }
`

const Title = styled.h2`
font-family: "Caveat Brush", cursive;
font-size: ${props => props.theme.fontxxxl};
text-transform: capitalize;
width: 80%;
color: ${props => props.theme.body};
align-self: flex-start;
margin: 0 auto;
@media (max-width: 64em){
  align-self: center;
  text-align: center;
  font-size: ${props => props.theme.fontxl};
  }


`

const SubText = styled.p`
font-size: ${props => props.theme.fontlg};

width: 80%;
color: ${props => props.theme.body};
align-self: flex-start;
margin: 1rem auto;
@media (max-width: 64em){
  align-self: center;
  text-align: center;
  font-size: ${props => props.theme.fontlg};
  }

`

const SubTextLight = styled.p`
font-size: ${props => props.theme.fontmd};

width: 80%;
color: ${props => `rgba(${props.theme.textRgba}, 0.6)`};
align-self: flex-start;
align-items: center;
margin: 1rem auto;
@media (max-width: 64em){
  align-self: center;
  text-align: center;
  }
`

const ButtonContainer = styled.div`
align-self: flex-start;
margin: 1rem auto;
width: 80%;
@media (max-width: 64em){
  align-self: center;
  text-align: center;
  }
`

const About = () => {
  return (
    <Section id="about">
      <Container>
    <Box><Carousel /></Box>
    <Box>
      <Title>All In One Out</Title>
      <SubText>3000 Citizens of AIOO-land are being minted for free + gas. (CC0) </SubText>
      <SubTextLight>Each citizen have been placed in an area in AIOO-land by the Evil twins. Each citizen have to fight in a Battle royale style game to survive and win prizes.</SubTextLight>
      <ButtonContainer>
      <ThemeProvider theme= {dark}>
      
      <a target="_blank" href="https://discord.gg/sJnWmpMBv4" rel="noreferrer"><Button text="Join Discord" ></Button></a>
      </ThemeProvider>
      </ButtonContainer>
      </Box>
      </Container>

    </Section>
  )
}

export default About