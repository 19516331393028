import React from 'react'
import styled from 'styled-components'
import img1 from '../assets/button.png';
//import {BrowserRouter as Router, Link} from 'react-router-dom';
const Btn = styled.button`
display: inline-block;
background-color: ${props => props.theme.text};

color: ${props => props.theme.body};
outline: none;
margin-right: 1rem;
margin-bottom: 1rem;
border: none;
font-size: ${props => props.theme.fontsm};
padding: 0.8rem 2.5rem;
border-radius: 50px;
cursor: Pointer;
transition: all 0.3s ease;
position: relative;
&:hover{
  transform: scale(0.9);
  }
&::after{
content: ' ';
position:absolute;
top:50%;
left: 50%;
transform: translate(-50%, -50%) scale(1);
border: 2px solid ${props => props.theme.text};
width: 100%;
height: 100%;
border-radius: 50px;
transition: all 0.5s ease;
}

img{
  width: 50px;
}

`

const Button = ({text, link}) => {
  
  
  return (
    
    <Btn Background-image={img1}>
     
      <a href={link} aria-label={text} target="_blank" rel="noreferrer">{text}</a>
    </Btn>
  )
}

export default Button