export const light ={
body: "#fff",
text: "#a10b0b",
bodyRgba: "32,32,32",
textRgba: "255,255,255",

carouselColor: '#EEEDDE',

fontxs:'0.75em',
fontsm:'0.875em',
fontmd:'1em',
fontlg:'1.25em',
fontxl:'2em',
fontxxl:'3em',
fontxxxl:'4em',

fontButton: '0.875em',
navHeight: '5rem',

}

export const dark ={
    body: "#202020",
    text: "#FFF",
    bodyRgba: "255,255,255",
    textRgba: "32,32,32",
    
    carouselColor: '#EEEDDE',
    
    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em',
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',
    
    fontButton: '0.875em',
    navHeight: '5rem',
    
    }