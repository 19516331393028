import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import { Navigation, Autoplay, EffectCards } from "swiper";

import img1 from '../assets/Nfts/2000.png';
import img2 from '../assets/Nfts/2001.png';
import img3 from '../assets/Nfts/2002.png';
import img4 from '../assets/Nfts/2003.png';
import img5 from '../assets/Nfts/2004.png';
import img6 from '../assets/Nfts/2005.png';
import img7 from '../assets/Nfts/2006.png';
import img8 from '../assets/Nfts/2007.png';
import img9 from '../assets/Nfts/2008.png';
import Arrow from '../assets/Arrow.svg';

const Container = styled.div`
width: 25vw;
height: 70vh;
img {
    width:100%
    }

    @media (max-width: 80em){
        width: 25vw;
        height: 60vh;
        margin: 1rem;
    img {
    width:100%
    }

    @media (max-width: 64em){
    width: 35vw;
    height: 60vh;
    margin: 1rem;
img {
width:100%
}

    }

img {
    width:100%
    }

@media (max-width: 54em){
    width: 45vw;
    height: 65vh;
    margin: 1rem;
img {
width:100%
}

    }

    @media (max-width: 48em){
        width: 45vw;
        height: 50vh;
        margin: 1rem;
    img {
    width:100%
    }
    
        }

        @media (max-width: 38em){
            width: 45vw;
            height: 40vh;
            margin: 1rem;
        img {
        width:100%
        }
        
            }

    }


.swiper{
    width: 100%;
    height: 100%;
}
.swiper-slide{
    background-color: ${props => props.theme.carouselColor};
    border-radius: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
}



.swiper-button-next{
    color: ${props => props.theme.text};
    rigth: 0;
    background-image: url(${Arrow});
    background-position: center;
    background-size: cover;
    top: 60%;
    width: 4rem;
    &:after{
        display: none;
    }

    @media (max-width: 64em){
        display: none;
        }
}


.swiper-button-prev{
    color: ${props => props.theme.text};
    left: 0;
    top: 60%;
    transform: rotate(180deg);
    background-image: url(${Arrow});
    background-position: center;
    background-size: cover;
    width: 4rem;
    @media (max-width: 64em){
        display: none;
        }
    &:after{
        display: none;
    }
}
`

const Carousel = () => {
  return (
   <Container>
<Swiper

autoplay={
{
    delay: 2000,
    disableOnInteraction:false,
}
}
navigation={true}
effect={"cards"}
        grabCursor={true}
        modules={[Navigation, Autoplay, EffectCards]}
        className="mySwiper"
      >
        <SwiperSlide><img src={img1} alt=""></img></SwiperSlide>
        <SwiperSlide><img src={img2} alt=""></img></SwiperSlide>
        <SwiperSlide><img src={img3} alt=""></img></SwiperSlide>
        <SwiperSlide><img src={img4} alt=""></img></SwiperSlide>
        <SwiperSlide><img src={img5} alt=""></img></SwiperSlide>
        <SwiperSlide><img src={img6} alt=""></img></SwiperSlide>
        <SwiperSlide><img src={img7} alt=""></img></SwiperSlide>
        <SwiperSlide><img src={img8} alt=""></img></SwiperSlide>
        <SwiperSlide><img src={img9} alt=""></img></SwiperSlide>
      </Swiper>

   </Container>
    
  )
}

export default Carousel