import { ThemeProvider } from "styled-components";
import React, {useEffect} from "react";
//import { ethers } from 'ethers';
import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import Navigation from "./components/Navigation";
//import Home from "./components/sections/Home";
import About from "./components/sections/About";
//import Roadmap from "./components/sections/Roadmap";
import Showcase from "./components/sections/Showcase";
//import Team from "./components/sections/Team";
//import Faq from "./components/sections/Faq";
import Footer from "./components/sections/Footer";
import ScrollToTop from "./components/ScrollToTop";
//import Mint from "./components/sections/Mint";
import Game from "./components/sections/Game";
//import {BrowserRouter as Router, Link} from 'react-router-dom';


function App() {

  useEffect(() => {
    document.title = "All In One Out";  
  }, []);
  
  /*const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 5000)
  }, [])
  
*/
  return (
    <>
    
    <><GlobalStyles /><ThemeProvider theme={light}>
          <Navigation />
          
          <About />
          
          <Showcase />
          <Game />

          


          <Footer />
          <ScrollToTop />

        </ThemeProvider></>

    </>
  );
}

export default App;
