import React from 'react'
import styled from 'styled-components'
import img1 from '../../assets/Nfts/robin_low.png';
import img2 from '../../assets/Nfts/crix_low.png';


import Twitter from "../Twitter";
import Opensea from "../Opensea";
import Discord from "../Discord";

const Section = styled.section
`
min-height: 100vh;
height: auto;
width: 100vw;
background-color: ${props => props.theme.text};
color: ${props => props.theme.body};
position: relative;
flex-direction: column;
display: flex;
justify-content: center;
align-items: center;

`
const Box = styled.div`
width: 50%;
height: auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media (max-width: 64em){
  width: 50%;
  }
 

`
const Container2 = styled.div`
width: 75%;

margin: 0 auto;
background-color: ${props => props.theme.text};
display: flex;
justify-content: center;
align-items: center;
@media (max-width: 64em){
  flex-direction: column;
  width: 100%;
  }
`
const Title = styled.h1`
font-family: "Caveat Brush", cursive;
font-size: ${props => props.theme.fontxxxl};

text-transform: capitalize;
color: ${props => props.theme.body};
justify-content: center;
margin: 1rem auto;
display: flex;
@media (max-width: 48em){
  font-size: ${props => props.theme.fontxxl};
}
width: fit-content;

`

const Container = styled.div`
width: 75%;
margin: 2rem auto;
display: flex;
justify-content: space-around;
align-items: center;
flex-wrap: wrap;

`
const ImageContainer = styled.div`
width: 15rem;
margin: 0 auto;
background-color: ${props => props.theme.body};
border-radius: 20px;
cursor: pointer;
padding: 20px;

img{
width: 100%;
height: auto;
transition: all 0.5s ease;

}
`

const Item = styled.div`
width: calc(20rem - 4vw);
padding: 1rem 0;
color: ${props => props.theme.body};
margin: 2rem 1rem;
position: relative;

border-radius: 20px;


&:hover {
  img{
    transform: translateY(-2rem) scale(1.1);
  }
}
`

const Name = styled.h2`
font-size: ${props => props.theme.fontlg};
display: flex;
align-items: center;
justify-content: center;
text-transform: uppercase;
color: ${props => props.theme.text};
margin-top: 1rem;
padding-bottom: 5px;
`

const Position = styled.h2`
font-size: ${props => props.theme.fontmd};
display: flex;
align-items: center;
justify-content: center;
text-transform: capitalize;
color: ${props => `rgba(${props.theme.bodyRgba}, 0.8)`};
padding-bottom: 10px;
font-weigth: 400;
`

const MemberComponent = ({img, name=" ", position=" ",link=" " }) =>
{
return(
  <Item>
    <ImageContainer>
    <a target="_blank" rel="noreferrer" href={link}><img src={img} alt={name} ></img></a>
      <Name>{name}</Name>
      <Position>{position}</Position>
      </ImageContainer>
     
  </Item>
)
}

const Footer = () => {
  return (
    
    <Section id="team">
        <Title>The Evil Twins</Title>
    <Container>

    <MemberComponent img={img1} name="Robin Karlsson" position="Founder" link="https://twitter.com/allinoneout" alt="" rel="noreferrer"/>
    <MemberComponent img={img2} name="Dr.Crix" position="Artist" link="https://instagram.com/drcrix" alt="" rel="noreferrer"/>
   </Container>
    <Container2> <Box><Twitter/></Box><Box><Opensea/></Box><Box><Discord/></Box></Container2>
    
    </Section>
    
  )
}

export default Footer