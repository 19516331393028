import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Dailyevent from '../Dailyevent'
import Button from '../Button'
import {dark} from '../../styles/Themes';

const Section = styled.section
`
min-height: 100vh;
width: 100%;
background-color: ${props => props.theme.text};
color: #FFF;
display:flex;
justify-content: center;
align-items: center;
position: relative;

`
const Container = styled.div`
width: 75%;
min-height: 80vh;
margin: 0 auto;
background-color: ${props => props.theme.text};
display: flex;
justify-content: center;
align-items: center;
@media (max-width: 64em){
  flex-direction: column;
  width: 100%;
  }
`

const Box = styled.div`
width: 50%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media (max-width: 64em){
  width:90%;
  }

  @media (max-width: 48em){
    width:100%;
    }
`

const Title = styled.h2`
font-family: "Caveat Brush", cursive;
font-size: ${props => props.theme.fontxxxl};
text-transform: capitalize;
width: 80%;
color: ${props => props.theme.body};
align-self: flex-start;
margin: 0 auto;
@media (max-width: 64em){
  align-self: center;
  text-align: center;
  font-size: ${props => props.theme.fontxl};
  }


`

const SubText = styled.p`
font-size: ${props => props.theme.fontlg};

width: 80%;
color: ${props => props.theme.body};
align-self: flex-start;
margin: 1rem auto;
@media (max-width: 64em){
  align-self: center;
  text-align: center;
  font-size: ${props => props.theme.fontlg};
  }

`

const SubTextLight = styled.p`
font-size: ${props => props.theme.fontmd};

width: 80%;
color: ${props => `rgba(${props.theme.textRgba}, 0.6)`};
align-self: flex-start;
align-items: center;
margin: 1rem auto;
@media (max-width: 64em){
  align-self: center;
  text-align: center;
  }
`

const ButtonContainer = styled.div`
align-self: flex-start;
margin: 1rem auto;
width: 80%;
@media (max-width: 64em){
  align-self: center;
  text-align: center;
  }
`

const Game = () => {
  return (
    <Section id="game">
      <Container>
    <Box><Dailyevent /></Box>
    <Box>
      <Title>Battle Royal</Title>
      <SubText>A passive, non-interactive Battle Royal game with Daily Events. </SubText>
      <SubTextLight>Just by holding the token you are entering the game with the chance to win NFT-Collectibles and our utility token.</SubTextLight>
      <ButtonContainer>
      <ThemeProvider theme= {dark}>
      <a target="_blank" rel="noreferrer" href="https://aioo.io"><Button text="To the Game" link="https://aioo.io"></Button></a>
      <a target="_blank" rel="noreferrer" href="https://whitepaper.allinoneout.com/"><Button text="White paper" ></Button></a>
      
      
      </ThemeProvider>
      </ButtonContainer>
      </Box>
      </Container>

    </Section>
  )
}

export default Game